<template>
  <div class="sports-films-bg">
    <common-banner
      :bannerTip="$t('OnlineCourses.tip2')"
      :bannerImg="require('../../assets/images/inspire-banner.webp')"
      :bannerTitle1="$t('SportsFilms.tip1')"
      :bannerTitle2="$t('SportsFilms.title')"
      colorType="orange"
    />
    <div class="content">
      <p>
        <span>{{ $t('SportsFilms.content_one') }}</span
        >{{ $t('SportsFilms.content_two') }}
      </p>
      <br />
      <p>
        <span>{{ $t('SportsFilms.content_three') }}</span>
      </p>
      <div class="content-title">
        <div class="left">
          <p class="line-left1"></p>
          <p class="line-left2"></p>
        </div>
        <p>{{ $t('SportsFilms.sports_title') }}</p>
        <div class="right">
          <p class="line-right1"></p>
          <p class="line-right2"></p>
        </div>
      </div>
    </div>

    <!--<div class='popover-con'>
      <div class='searchbar-container'>
        <div class='search-container'>
          <span>
            <i class="el-icon-search"></i>
            <input
              v-model="searchQuery"
              :placeholder="$t('SportsFilms.input_pla')"
            />
          </span>
        </div>
        <div class='search-buttons-container'>
          <div class='search-button' @click="searchBtn">
            <div>
              {{ $t("SportsFilms.search") }}
            </div>
          </div>
          <div class='extra-search-container' @click='switchExtraSearch'>
            <span>
              {{ $t('SportsFilms.search_stage') }} &#9660;
            </span>
          </div>
        </div>
      </div>
    </div>-->

    <!-- <div class="content">{{ $t('SportsFilms.content') }}</div>
    <div class="sports-title">
      <p>{{ $t('SportsFilms.sports_title') }}</p>
      <p class="line-left1"></p>
      <p class="line-left2"></p>
      <p class="line-right1"></p>
      <p class="line-right2"></p>
    </div> -->

    <!--<div class="search">
    <div class="input">
        <i class="el-icon-search"></i>
        <input
          class="input-con"
          v-model="input"
          :placeholder="$t('SportsFilms.input_pla')"
        />
      </div>
      <div class="search-btn">
        <span>{{ $t("SportsFilms.search_btn") }}</span>
      </div>
      </div>
    placement="bottom-end"
      :width="990"
      trigger="click"
      :show-arrow="false"
      transition="fade-in-linear" --
    <div class="popover">
    <el-button class="btn-stage"
        ><span class="con">{{ $t('SportsFilms.search_stage') }}</span
        ><i class="el-icon-caret-bottom e"></i
      ></el-button>
    </div>-->

    <div class="popover-con" v-loading="searching">
      <p class="tit title">
        <span style="display: inline-block">{{ $t('SportsFilms.search_stage') }}</span>
        <el-button round class="popover-search-button" @click="searchBtn">{{ $t('SportsFilms.search') }}</el-button>
      </p>
      <div class="popover-lists">
        <div class="left">
          <div class="item" v-for="(item, index) in searchLeftLists" :key="index" :style="index === 2 ? 'border:0' : ''">
            <p class="tit">{{ $t('SportsFilms.' + item.tit) }}</p>
            <div class="txts-left">
              <!-- <el-checkbox-group v-model="checkList"> -->
              <el-checkbox
                class="txt"
                v-for="(item1, index) in index === 0 ? item.txts.slice(0, 3) : index === 1 ? item.txts.slice(0, 3) : item.txts.slice(0, 2)"
                :key="index"
                :label="item1.val"
                v-model="filters[item.category]"
                >{{ $t('SportsFilms.' + item1.tit) + (!item1.all ? ` (${filmsCount[item.category][item1.val]})` : '') }}</el-checkbox
              >
              <!-- </el-checkbox-group> -->
            </div>
            <div class="txts-right">
              <el-checkbox
                class="txt"
                v-for="(item1, index) in index === 0 ? item.txts.slice(3, 7) : index === 1 ? item.txts.slice(3, 6) : item.txts.slice(2, 4)"
                :key="index"
                :label="item1.val"
                v-model="filters[item.category]"
                >{{ $t('SportsFilms.' + item1.tit) + (!item1.all ? ` (${filmsCount[item.category][item1.val]})` : '') }}</el-checkbox
              >
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="right">
          <div class="item" v-for="(item, index) in searchRightLists" :key="index" :style="index === 2 && screenWidth > 480 ? 'border:0' : ''" :class="index === 0 ? 'right-item0' : ''">
            <p class="tit">{{ $t('SportsFilms.' + item.tit) }}</p>
            <div class="txts-left">
              <!-- <el-checkbox-group v-model="checkList"> -->
              <el-checkbox
                class="txt"
                v-for="(item1, index) in index === 0 ? item.txts.slice(0, 3) : index === 1 ? item.txts.slice(0, 3) : item.txts.slice(0, 2)"
                :key="index"
                :style="$t('SportsFilms.' + item1.tit) == ' ' ? 'opacity: 0;' : ''"
                :label="item1.val"
                v-model="filters[item.category]"
                >{{ $t('SportsFilms.' + item1.tit) + (!item1.all ? ` (${filmsCount[item.category][item1.val]})` : '') }}</el-checkbox
              >
              <!-- </el-checkbox-group> -->
            </div>
            <div class="txts-right">
              <el-checkbox
                class="txt"
                v-for="(item1, index) in index === 0 ? item.txts.slice(3, 5) : index === 1 ? item.txts.slice(3, 6) : item.txts.slice(2, 3)"
                :key="index"
                :style="$t('SportsFilms.' + item1.tit) == ' ' ? 'opacity: 0;' : ''"
                :label="item1.val"
                v-model="filters[item.category]"
                >{{ $t('SportsFilms.' + item1.tit) + (!item1.all ? ` (${filmsCount[item.category][item1.val]})` : '') }}</el-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- </div>/-->
    <div ref="searchResult" style="height: 1px"></div>
    <div class="pop-video pop-video-bg" v-if="searchLists">
      <!-- <div class="bage"></div> -->
      <div class="bage"></div>
      <div class="pop-video-title">
        <span>{{ $t('SportsFilms.search_title') }}</span>
      </div>
      <div class="list-scroll">
        <div class="video-list">
          <div class="video-item" v-for="(item, index) in searchLists" :key="index" @click="goPush(item.id)">
            <div class="img" v-loading="searchImagesLoad[index]" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 150, 150, 0.5)">
              <img @load="searchImgLoad(index)" v-if="item.cover_url" style="cursor: pointer" :src="item.cover_url" alt="" />
              <img v-else src="@/assets/images/default.png" alt="" />
            </div>
            <div class="content">
              <p class="tit">{{ item.title }}</p>
              <p class="txt">
                {{ $t('SportsFilms.browse_one') + item.browse_count + $t('SportsFilms.browse_two') }}
              </p>
            </div>
          </div>
          <i v-for="(item, index) in searchLists.length - 1" :key="index + 'i'"></i>
        </div>
      </div>
    </div>
    <div class="pop-video" v-if="videoListsLenght > 0">
      <div class="bage"></div>
      <div class="pop-video-title">
        <span>{{ $t('SportsFilms.pop_title1') }}</span>
      </div>
      <div class="list-scroll">
        <div class="video-list">
          <div class="video-item" v-for="(item, index) in videoLists" :key="index" @click="goPush(item.id)">
            <div class="img" v-loading="imagesLoad[index]" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 150, 150, 0.5)">
              <img @load="imgLoad(index)" v-if="item.cover_url" style="cursor: pointer" :src="item.cover_url" alt="" />
              <img v-else src="@/assets/images/default.png" alt="" />
            </div>
            <div class="content">
              <p class="tit">{{ item.title }}</p>
              <p class="txt">
                {{ $t('SportsFilms.browse_one') + item.browse_count + $t('SportsFilms.browse_two') }}
              </p>
            </div>
          </div>
          <i v-for="(item, index) in videoLists.length - 1" :key="index + 'i'"></i>
        </div>
      </div>
    </div>
    <div style="margin: 10% 0" v-else>loading...</div>
    <!-- <div class="pop-video pop-video2">
      <div class="bage"></div>
      <div class="pop-video-title">
        <span>{{ $t("SportsFilms.pop_title2") }}</span>
      </div>
      <div class="video-list">
        <div
          class="video-item"
          v-for="(item, index) in videoLists"
          :key="index"
          @click="goPush(item.id)"
        >
          <div class="img">
            <img style="cursor: pointer" :src="item.cover_url" alt="" />
          </div>
          <div class="content">
            <p class="tit">{{ item.title }}</p>
            <p class="txt">
              {{
                $t("SportsFilms.browse_one") +
                item.browse_count +
                $t("SportsFilms.browse_two")
              }}
            </p>
          </div>
        </div>
      </div>
    </div> -->
    <div class="pop-video-title look-more" @click="getAllFilms" v-show="filmsShow">
      <span>{{ $t('SportsFilms.look_more') }}</span>
      <i class="el-icon-caret-right"></i>
    </div>
  </div>
</template>
<script>
import { getFilms, getFilmsList, getAllFilms, countFilmsByCategory } from '@/api/videos'
import CommonBanner from '@/components/CommonBanner'
export default {
  components: {
    CommonBanner,
  },
  data() {
    return {
      loggedIn: false,
      input: '',
      mcheckbox: [],
      filters: {
        sport_event: [],
        age: [],
        value: [],
        activity_target: [],
        active_level: [],
        availability: [],
      },
      sport_event: ['newly_emerged_ball_games', 'fistifight_sports', 'basic_movement_ability', 'fitness_and_stretching', 'sport_event_all'],
      age: ['junior_primary', 'senior_primary', 'junior_secondary', 'senior_secondary', 'age_all'],
      value: ['team_spirit', 'emotional_skills', 'intelligent_development', 'team_skills', 'physical_health', 'value_all'],
      activity_target: ['ice-breaking', 'warm-up', 'theme_activity', 'coordination_and_agility', 'organization_and_reflection', 'activity_target_all'],
      active_level: ['low_intensity', 'medium_intensity', 'high_intensity', 'active_level_all'],
      availability: ['special_education_need', 'physical_disability', 'availability_all'],
      videoLists: null,
      videoListsLenght: 0,
      searchLists: null,
      checkList: [],
      imagesLoad: [true, true, true, true],
      searchImagesLoad: [true, true, true, true, true, true, true, true],
      searchLoad: true,
      searchQuery: '',
      filmsCount: {},
      extraSearch: false,
      searching: false,
      searchLeftLists: [
        {
          tit: 'search_tit1',
          category: 'sport_event',
          txts: [
            // { tit: 'search_txt11', val: 'traditional_ball_games' },
            { tit: 'search_txt12', val: 'fistifight_sports', all: false },
            { tit: 'search_txt13', val: 'basic_movement_ability', all: false },
            { tit: 'search_all', val: 'sport_event_all', all: true },
            // { tit: 'search_txt14', val: 'athletics' },
            { tit: 'search_txt15', val: 'newly_emerged_ball_games', all: false },
            { tit: 'search_txt16', val: 'fitness_and_stretching', all: false },
            //{ tit: 'search_txt17', val: 'racket_sports' },
          ],
        },
        {
          tit: 'search_tit3',
          category: 'activity_target',
          txts: [
            { tit: 'search_txt31', val: 'ice-breaking', all: false },
            { tit: 'search_txt32', val: 'warm-up', all: false },
            { tit: 'search_txt33', val: 'theme_activity', all: false },
            { tit: 'search_txt34', val: 'coordination_and_agility', all: false },
            { tit: 'search_txt35', val: 'organization_and_reflection', all: false },
            { tit: 'search_all', val: 'activity_target_all', all: true },
          ],
        },
        {
          tit: 'search_tit5',
          category: 'active_level',
          txts: [
            { tit: 'search_txt51', val: 'low_intensity', all: false },
            { tit: 'search_txt52', val: 'medium_intensity', all: false },
            { tit: 'search_txt53', val: 'high_intensity', all: false },
            { tit: 'search_all', val: 'active_level_all', all: true },
          ],
        },
      ],
      searchRightLists: [
        {
          tit: 'search_tit2',
          category: 'age',
          txts: [
            { tit: 'search_txt21', val: 'junior_primary', all: false },
            { tit: 'search_txt25', val: 'senior_primary', all: false },
            { tit: 'search_all', val: 'age_all', all: true },
            { tit: 'search_txt22', val: 'junior_secondary', all: false },
            { tit: 'search_txt26', val: 'senior_secondary', all: false },
          ],
        },
        {
          tit: 'search_tit4',
          category: 'value',
          txts: [
            { tit: 'search_txt41', val: 'team_spirit', all: false },
            { tit: 'search_txt42', val: 'emotional_skills', all: false },
            { tit: 'search_txt43', val: 'intelligent_development', all: false },
            { tit: 'search_txt44', val: 'team_skills', all: false },
            { tit: 'search_txt45', val: 'physical_health', all: false },
            { tit: 'search_all', val: 'value_all', all: true },
          ],
        },
        {
          tit: 'search_tit6',
          category: 'availability',
          txts: [
            { tit: 'search_txt62', val: 'physical_disability', all: false },
            { tit: 'search_all', val: 'availability_all', all: true },
            { tit: 'search_txt61', val: 'special_education_need', all: false },
          ],
        },
      ],
      screenWidth: window.document.body.clientWidth,
      filmsShow: true,
      changingFilters: false,
    }
  },
  methods: {
    switchExtraSearch() {
      this.extraSearch = !this.extraSearch
    },
    checkLogin() {
      let token = window.sessionStorage.getItem('token')
      this.loggedIn = token == null ? false : true
    },
    async countFilmsByCategory() {
      try {
        let res = await countFilmsByCategory()
        this.filmsCount = res.data
      } catch (err) {
        this.$notify.error({
          title: this.$t('SportsFilms.search_error_tit'),
          message: this.$t('SportsFilms.search_error'),
          duration: '1500',
        })
      }
    },
    searchImgLoad(index) {
      this.$set(this.searchImagesLoad, index, false)
    },
    imgLoad(index) {
      this.$set(this.imagesLoad, index, false)
    },
    searchBtn() {
      if (!this.loggedIn) {
        this.$notify.error({
          title: this.$t('SportsFilms.search_error_tit'),
          message: this.$t('SportsFilms.no_login'),
          duration: '4000',
        })
        return
      }
      let data = this.getUrl()
      if (data === '') {
        this.searchLists = null
        return
      }
      this.searching = true
      getFilmsList(data).then((res) => {
        if (!this.loggedIn) {
          this.searchLists = []
          for (let i = 0; i < 4 && i < res.data.data.length; i++) {
            this.searchLists.push(res.data.data[i])
          }
        } else {
          this.searchLists = res.data.data
        }
        this.searching = false
        if (this.searchLists.length === 0) {
          this.searchLists = null
          // this.$message.error(this.$t('SportsFilms.search_error'))
          this.$notify.error({
            title: this.$t('SportsFilms.search_error_tit'),
            message: this.$t('SportsFilms.search_error'),
            duration: '1500',
          })
        } else {
          this.$notify.success({
            title: this.$t('SportsFilms.search_error_tit'),
            message: this.$t('SportsFilms.search_success'),
            duration: '1500',
          })
          this.$refs.searchResult.scrollIntoView()
        }
      })
    },
    getFilms(page, count) {
      getFilms(page, count).then((res) => {
        this.videoLists = res.data.data
        this.videoListsLenght = res.data.total
      })
    },
    getAllFilms() {
      if (!this.loggedIn) {
        this.$notify({
          type: 'error',
          title: this.$t('SportsFilms.search_error_tit'),
          message: this.$t('SportsFilms.no_login'),
          duration: '4000',
        })
        return
      }
      this.$globals.viewAllFilms = true
      getAllFilms().then((res) => {
        this.videoLists = res.data.data
        this.videoListsLenght = res.data.total
      })
      this.filmsShow = false
    },
    goPush(flimId) {
      this.$router.push('sports-films/' + flimId)
    },
    getUrl() {
      let sportEvent = this.filters.sport_event
      let age = this.filters.age
      let activityTarget = this.filters.activity_target
      let value = this.filters.value
      let activeLevel = this.filters.active_level
      let availability = this.filters.availability
      let str1 = sportEvent.join(',') !== '' ? '&sport_event=' + sportEvent.join(',') : ''
      let str2 = age.join(',') !== '' ? '&age=' + age.join(',') : ''
      let str3 = activityTarget.join(',') !== '' ? '&activity_target=' + activityTarget.join(',') : ''
      let str4 = value.join(',') !== '' ? '&value=' + value.join(',') : ''
      let str5 = activeLevel.join(',') !== '' ? '&active_level=' + activeLevel.join(',') : ''
      let str6 = availability.join(',') !== '' ? '&availability=' + availability.join(',') : ''
      return str1 + str2 + str3 + str4 + str5 + str6 + '&search=' + this.searchQuery
    },
  },
  watch: {
    'filters.sport_event'(newVal, oldVal) {
      if (newVal.includes('sport_event_all') && !oldVal.includes('sport_event_all')) {
        this.$nextTick(() => {
          this.filters.sport_event = this.sport_event
        })
      } else if (!newVal.includes('sport_event_all') && oldVal.includes('sport_event_all')) {
        this.$nextTick(() => {
          this.filters.sport_event = []
        })
      } else if (newVal.includes('sport_event_all') && newVal.length < oldVal.length) {
        this.$nextTick(() => {
          let i = this.filters.sport_event.indexOf('sport_event_all')
          if (i > -1) {
            this.filters.sport_event.splice(i, 1)
          }
        })
      }
    },
    'filters.age'(newVal, oldVal) {
      if (newVal.includes('age_all') && !oldVal.includes('age_all')) {
        this.$nextTick(() => {
          this.filters.age = this.age
        })
      } else if (!newVal.includes('age_all') && oldVal.includes('age_all')) {
        this.$nextTick(() => {
          this.filters.age = []
        })
      } else if (newVal.includes('age_all') && newVal.length < oldVal.length) {
        this.$nextTick(() => {
          let i = this.filters.age.indexOf('age_all')
          if (i > -1) {
            this.filters.age.splice(i, 1)
          }
        })
      }
    },
    'filters.value'(newVal, oldVal) {
      if (newVal.includes('value_all') && !oldVal.includes('value_all')) {
        this.$nextTick(() => {
          this.filters.value = this.value
        })
      } else if (!newVal.includes('value_all') && oldVal.includes('value_all')) {
        this.$nextTick(() => {
          this.filters.value = []
        })
      } else if (newVal.includes('value_all') && newVal.length < oldVal.length) {
        this.$nextTick(() => {
          let i = this.filters.value.indexOf('value_all')
          if (i > -1) {
            this.filters.value.splice(i, 1)
          }
        })
      }
    },
    'filters.activity_target'(newVal, oldVal) {
      if (newVal.includes('activity_target_all') && !oldVal.includes('activity_target_all')) {
        this.$nextTick(() => {
          this.filters.activity_target = this.activity_target
        })
      } else if (!newVal.includes('activity_target_all') && oldVal.includes('activity_target_all')) {
        this.$nextTick(() => {
          this.filters.activity_target = []
        })
      } else if (newVal.includes('activity_target_all') && newVal.length < oldVal.length) {
        this.$nextTick(() => {
          let i = this.filters.activity_target.indexOf('activity_target_all')
          if (i > -1) {
            this.filters.activity_target.splice(i, 1)
          }
        })
      }
    },
    'filters.active_level'(newVal, oldVal) {
      if (newVal.includes('active_level_all') && !oldVal.includes('active_level_all')) {
        this.$nextTick(() => {
          this.filters.active_level = this.active_level
        })
      } else if (!newVal.includes('active_level_all') && oldVal.includes('active_level_all')) {
        this.$nextTick(() => {
          this.filters.active_level = []
        })
      } else if (newVal.includes('active_level_all') && newVal.length < oldVal.length) {
        this.$nextTick(() => {
          let i = this.filters.active_level.indexOf('active_level_all')
          if (i > -1) {
            this.filters.active_level.splice(i, 1)
          }
        })
      }
    },
    'filters.availability'(newVal, oldVal) {
      if (newVal.includes('availability_all') && !oldVal.includes('availability_all')) {
        this.$nextTick(() => {
          this.filters.availability = this.availability
        })
      } else if (!newVal.includes('availability_all') && oldVal.includes('availability_all')) {
        this.$nextTick(() => {
          this.filters.availability = []
        })
      } else if (newVal.includes('availability_all') && newVal.length < oldVal.length) {
        this.$nextTick(() => {
          let i = this.filters.availability.indexOf('availability_all')
          if (i > -1) {
            this.filters.availability.splice(i, 1)
          }
        })
      }
    },
  },
  created() {
    this.checkLogin()
    this.countFilmsByCategory()
    //獲取最多人搜索的影片
    if (this.$globals.viewAllFilms) {
      this.filmsShow = false
      this.getAllFilms()
    } else {
      this.getFilms(1, 4)
    }
  },
}
</script>
<style lang="scss" scoped>
.search-buttons-container {
  display: flex;
  width: 35%;
}
.search-button {
  background: #28327b;
  display: inline-block;
  height: 55px;
  width: 40%;
  padding: 0px 20px;
  text-decoration: none;
  cursor: pointer;
  transform: skew(-28deg);
}
.search-button div {
  transform: skew(28deg);
  position: relative;
  top: 15px;
  color: #fff;
}

.searchbar-container {
  display: flex;
}

.search-container {
  width: 65%;
  text-align: center;
  height: 0;
  position: relative;
  border-right: 30px solid transparent;
  border-top: 55px solid #f15922;
  box-sizing: content-box;
  span {
    position: relative;
    top: -42px;
    left: -7%;
    width: 80%;
    color: #000;
  }
}
.search-container span input {
  margin-left: 20px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  width: 70%;
}
.search-container span input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.extra-search-container {
  cursor: pointer;
  width: 50%;
  text-align: center;
  height: 0;
  position: relative;
  border-bottom: 55px solid #f15922;
  border-left: 30px solid transparent;
  box-sizing: content-box;
  span {
    position: relative;
    top: 15px;
    left: -7px;
    color: #fff;
  }
}

.sports-films-bg {
  .content {
    width: 90%;
    margin: 4% auto;
    p {
      font-size: 26px;
    }
    .content-title {
      margin: 4% auto;
      letter-spacing: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f15922;
      font-weight: bold;
      // font-family: 'NotoSansCJKtc-Bold';
      transform: skew(-10deg);
      overflow: visible;
      p {
        font-size: 32px;
      }
      .left {
        width: 60px;
        height: 50px;
        position: relative;
        .line-left1 {
          width: 30px;
          height: 2px;
          background: #f15922;
          position: absolute;
          top: 20px;
          left: 12px;
        }
        .line-left2 {
          width: 30px;
          height: 2px;
          background: #f15922;
          position: absolute;
          top: 28px;
          left: 0px;
        }
      }
      .right {
        width: 60px;
        height: 50px;
        position: relative;
        .line-right1 {
          width: 40px;
          height: 2px;
          background: #f15922;
          position: absolute;
          top: 20px;
          right: 0px;
        }
        .line-right2 {
          width: 20px;
          height: 2px;
          background: #f15922;
          position: absolute;
          top: 28px;
          right: 18px;
        }
      }
    }
  }
  .sports-title {
    margin: 4% auto;
    width: 30%;
    letter-spacing: 2px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f15922;
    // font-family: 'NotoSansCJKtc-Bold';
    // background: red;
    transform: skew(-10deg);
    overflow: visible;
    .line-left1 {
      width: 30px;
      height: 2px;
      background: #f15922;
      position: absolute;
      top: 18px;
      left: 40px;
    }
    .line-left2 {
      width: 30px;
      height: 2px;
      background: #f15922;
      position: absolute;
      top: 26px;
      left: 28px;
    }
    .line-right1 {
      width: 40px;
      height: 2px;
      background: #f15922;
      position: absolute;
      top: 18px;
      right: 30px;
    }
    .line-right2 {
      width: 20px;
      height: 2px;
      background: #f15922;
      position: absolute;
      top: 26px;
      right: 50px;
    }
  }
  .popover-con {
    .el-checkbox {
      color: #333 !important;
    }
    width: 100%;
    padding: 0 50px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 22px;
      margin: 0px auto;
      padding: 20px 0;
      border-bottom: 1px solid #333333;
    }
    .popover-search-button {
      width: 180px;
      height: 70px;
      margin-right: 52px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 29px;
      font-weight: bold;
      color: #fff;
      border-radius: 25px;
      background-color: #f15922;
      &:focus,
      &:hover {
        background-color: #f15922;
        border-color: #f15922;
        color: #666;
      }
    }
    .tit {
      font-size: 30px;
      .banner-tit1 {
        width: auto;
        margin-top: 18%;
        padding-left: 21%;
        text-align: left;
        font-size: 68px;
        color: #fff;
        transform: skew(6deg);
        // font-family: 'NotoSansCJKtc-Bold';
      }
    }
  }
  .pop-video-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 50%;
    width: 423px;
    height: 92px;
    font-size: 34px;
    font-weight: bold;
    color: #fff;
    padding: 0% 30px;
    background: #28327b;
    transform: skew(-15deg) translate(-50%, -46px);
    span {
      transform: skew(15deg);
    }
  }
  .pop-video {
    width: 100%;
    position: relative;
    margin: 10% auto 0%;
    padding: 5% 0%;
    .bage {
      width: 100%;
      background: #f2f2f2;
      height: 50%;
      position: absolute;
      top: 0;
      z-index: -1;
    }
    .video-list {
      width: 100%;
      // margin: 5% auto;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      // background: red;
      .video-item {
        width: 22%;
        margin-top: 2%;
        display: flex;
        flex-direction: column;
        .img {
          height: 288px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content {
          text-align: left;
          width: 100%;
          height: 20%;
          .tit {
            font-size: 28px;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 20px;
          }
        }
      }
      i {
        width: 22%;
      }
    }
  }

  .pop-video-bg {
    //background: #f2f2f2;
    width: 100%;
    position: relative;
    margin: 10% auto 0%;
    padding: 5% 0%;
  }
  .look-more {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 auto 80px auto;
    background: #f15922;
    transform: skew(-15deg);
    cursor: pointer;
    span {
      transform: skew(15deg);
    }
    i {
      transform: skew(15deg);
    }
  }
}

.popover-lists {
  width: 100%;
  padding: 25px 0;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .line {
    height: 510px;
    width: 1px;
    background: #333333;
    margin-top: 25px;
  }
  .left,
  .right {
    // flex: 1;
    width: 50%;
    .item {
      display: flex;
      border-bottom: 1px solid #333333;
      padding: 15px 0;
      display: flex;
      justify-content: flex-start;
      .tit {
        width: 25%;
        margin-top: 12px;
        font-size: 26px;
      }
      .txts-left,
      .txts-right {
        width: 35%;
        margin-left: 5px 0%;
        text-align: left;
        .txt {
          display: flex;
          padding: 15px 0;
          font-size: 30px;
          line-height: 30px;
          ::v-deep .el-checkbox__input {
            .el-checkbox__inner {
              width: 15px;
              height: 15px;
            }
          }
          ::v-deep .el-checkbox__label {
            font-size: 22px;
          }
          ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #28327b;
          }
          ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
          ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: #28327b;
            border-color: #28327b;
          }
        }
      }
    }
  }
  .right {
    width: 43%;
  }
}
@media screen and (max-width: 480px) {
  .search-buttons-container {
    display: flex;
    width: 100%;
    margin-top: 10px;
  }
  .search-button div {
    transform: skew(0deg);
    top: -40px;
    position: relative;
    color: #fff;
  }
  .search-button {
    background: none;
    display: inline-block;
    height: 0;
    width: 35%;
    text-decoration: none;
    cursor: pointer;
    border-right: 30px solid transparent;
    border-top: 55px solid #2963bd;
    transform: skew(0deg);
  }

  /*.search-button {
    width: 50%;
    cursor: pointer;
    text-align: center;
    height: 0; 
    position: relative;
    border-right: 30px solid transparent;
    border-top: 55px solid #2963BD;
    box-sizing: content-box;
    transform: skew(0deg);
  }*/
  /*.search-button {
    width: 50%;
    cursor: pointer;
    text-align: center;
    height: 55px; 
    position: relative;

  }*/

  .searchbar-container {
    display: flex;
    flex-direction: column;
  }

  .search-container {
    width: 100%;
    text-align: center;
    height: 0;
    position: relative;
    border-right: 0px solid transparent;
    border-top: 55px solid #f15922;
    box-sizing: content-box;
    span {
      position: relative;
      top: -42px;
      left: -7%;
      width: 80%;
      color: #000;
    }
  }
  .extra-search-container {
    cursor: pointer;
    margin-left: 42.9%;
    width: 40%;
    text-align: center;
    height: 0;
    position: absolute;
    border-bottom: 55px solid #f15922;
    border-left: 30px solid transparent;
    box-sizing: content-box;
    span {
      position: relative;
      top: 15px;
      left: -7px;
      color: #fff;
    }
  }
  .sports-films-bg {
    .content {
      width: 100%;
      padding: 50px;
      margin: 0;
      letter-spacing: 1px;
      box-sizing: border-box;
      p {
        font-size: 30px;
        text-align: left;
        span {
          font-weight: bold;
        }
      }
      .content-title {
        .left {
          .line-left1 {
            left: 18px;
          }
          .line-left2 {
            left: 8px;
          }
        }
        p {
          font-size: 32px;
          letter-spacing: 0px;
        }
        .right {
          .line-right1 {
            right: 7px;
          }
          .line-right2 {
            right: 26px;
          }
        }
      }
    }
    .popover-con {
      width: 90%;
      margin-bottom: 25%;
      box-shadow: none;
      .popover-lists {
        flex-wrap: wrap;
        padding-top: 0;
        padding-bottom: 0;
        .item {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .tit {
            width: 100%;
            font-size: 30px;
            margin: 10px 0 20px 0;
          }
          .txt {
            ::v-deep .el-checkbox__label {
              font-size: 26px;
            }
          }
          .txts-left {
            width: 50%;
          }
          .txts-right {
            width: 50%;
          }
        }
        .left {
          width: 100%;
        }
        .line {
          height: 1px;
          width: 100%;
        }
        .right {
          width: 100%;
        }
      }
      .popover-search-button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        margin-right: 0px;
        border-radius: 30px;
        font-size: 27px;
      }
    }
    .pop-video {
      .pop-video-title {
        height: 100px;
        width: 47%;
        span {
          font-size: 32px;
        }
      }
    }
    .look-more {
      width: 47%;
      height: 100px;
      margin: 0% auto 23%;
      span {
        font-size: 32px;
        margin-left: 14px;
      }
    }
    .pop-video-bg {
      .pop-video-title {
        width: 47%;
        height: 100px;
        span {
          font-size: 32px;
        }
      }
    }
    .list-scroll {
      width: 100%;
      margin: 12% auto;
      overflow: hidden;
      overflow-x: auto;
      .video-list {
        width: auto;
        justify-content: flex-start;
        flex-wrap: nowrap;
        padding: 0 30px;
        .video-item {
          flex-shrink: 0;
          width: 250px;
          padding: 0 20px;
          &:last-child {
            padding-right: 50px;
          }
          .img {
            height: 250px;
            img {
              object-fit: cover;
            }
          }
          .content {
            padding: 30px 0;
            .tit {
              font-size: 30px;
            }
            .txt {
              font-size: 24px;
              letter-spacing: 0px;
            }
          }
        }
      }
    }
  }
}
</style>
